import React from "react"
import Player from "../../blocks/player"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  player: playersYaml(slug: { eq: "maksimov" }) {
    ...PlayerInfo
  }
  stats: allStatsYaml {
    nodes {
      key
      value_ru
      average
    }
  }
}`

export default class Maksimov extends Player {
  renderDescription() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Мощный нападающий, обладающий при своем росте хорошей динамикой. Координирован при работе с мячом, нацелен на верховые мячи, хорошо открывается в штрафной.</p>
          <p>Владеет пасом в касание на ближнего партнера, что позволяет использовать его как в качестве «столба», так и вспомогательного форварда. Есть поставленный удар со средней и дальней дистанции, в случае необходимости способен использовать «чужую» левую ногу.</p>
          <p>Умеет начать отбор мяча при его потере, не выключается из эпизодов. Хорошо стоит на ногах и не теряет скорость при сопротивлении со стороны защитника.</p>
          <div className="video">
            <iframe
              title={ this.fullName() }
              src="https://www.youtube.com/embed/ZtDLQKa0of4"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
          <p>В сезоне 2017/18 в осенней части еврокубков сохранял первое место среди бомбардиров Лиги Европы за счет голов, забитых за «Тракай» в квалификационном раунде (7).</p>
          <img src="/images/players/maksimov/photo1.jpg" alt={ this.fullName() } />
        </div>
      </div>
    )
  }
}